html {
  background: #fff;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -o-overflow-scrolling: touch;
  max-width: 750px;
  margin: 0 auto;
  background-color: #ffffff;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.am-navbar-light .am-navbar-left {
  padding-left: 24px;
}

.am-navbar-left-icon {
  color: #333;
  font-size: 40px;
}

.am-navbar-light .am-navbar-title {
  font-size: 34px;
  font-weight: bold;
}

.substring {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.substring2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

a {
  text-decoration: none;
  color: inherit;
}

.section-title {
  font-size: 32px;
  font-weight: 600;
  color: #333333;
  line-height: 32px;
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
  .view-more {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 500;
    color: #667280;
    line-height: 24px;
  }
}

.load-more {
  height: 22px;
  font-size: 24px;
  font-weight: 500;
  color: #667280;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 200px;
  cursor: pointer;
  margin-top: 24px;
  .icon-arrow-down {
    height: 14px;
    margin-left: 8px;
  }
}

@hack: true;@import "/home/gitlab-runner/builds/a5j7AGgb/0/iguopin-web/iguopin-heilongjiang/packages/h5/src/styles/theme.less";