.banner {
  position: relative;
  width: 100%;

  .banner-img {
    width: 100%;
    height: 265px;
    object-fit: cover;
  }

  .lang-act {
    position: absolute;
    top: 33px;
    left: 60px;
    color: #fff;

    a {
      color: #fff;
    }
  }

  .banner-act {
    position: absolute;
    top: 33px;
    right: 40px;
    display: flex;
    align-items: center;
  }

  .link-btn {
    cursor: pointer;
    text-align: center;
    line-height: 48px;
    color: #fff;
    font-size: 22px;
    width: 150px;
    height: 44px;
    background: linear-gradient(179deg, #0c8afc 56%, #23c6f2);
    border-radius: 4px;
    &:first-child {
      margin-right: 24px;
    }
    // background-image: url("../../images/identity_bg.png");
    // background-size: contain;
    // background-repeat: no-repeat;
  }

  .search-container {
    position: absolute;
    bottom: 66px;
    left: 50%;
    transform: translateX(-50%);
  }

  .stat-num {
    position: absolute;
    top: 73%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;

    .stat-num-item {
      // background-image: url("../../images/num_bg.png");
      // background-size: 100% 100%;
      // background-position: center;
      width: 172px;
      height: 100px;
      background-repeat: no-repeat;
      margin-left: 4px;
      margin-right: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .name {
        margin-top: 4px;
        color: #fff;
        font-size: 20px;
        font-weight: bolder;
      }

      .num {
        //margin-top: 4px;
        color: #00f2fe;
        font-size: 24px;
        line-height: 32px;
        height: 32px;
        font-weight: bolder;
      }
    }
  }
}

.nav {
  position: relative;
  font-size: 25px;

  .nav-list {
    padding-left: 45px;
    padding-right: 45px;
    line-height: 90px;
    white-space: nowrap;
    overflow-x: scroll;

    .nav-item {
      padding: 10px 30px;
      font-size: 28px;
      color: #666666;
      line-height: 40px;

      &.active,
      &:active {
        color: #1c53c5;
        border-bottom: 4px solid #1c53c5;
      }
    }
  }
}

@hack: true;@import "/home/gitlab-runner/builds/a5j7AGgb/0/iguopin-web/iguopin-heilongjiang/packages/h5/src/styles/theme.less";