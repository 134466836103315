.search-wrap {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 509px;
  height: 58px;
  background: #f1f6fe;
  border-radius: 8px;

  .search-text {
    flex: 1;
    font-size: 24px;
    color: @font-gray;
    border: none;
    background-color: transparent;
  }

  .am-icon-search {
    color: #4c4c4c;
    vertical-align: middle;
  }

  .search-icon {
    margin: 0 13px 0 30px;
  }
}

@hack: true;@import "/home/gitlab-runner/builds/a5j7AGgb/0/iguopin-web/iguopin-heilongjiang/packages/h5/src/styles/theme.less";