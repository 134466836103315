.zhanjiang-index {
  color: @default-color;
  background-color: #f1f6fe;

  .content {
    width: 100%;
    min-height: calc(100vh - 290px);
  }
}

@hack: true;@import "/home/gitlab-runner/builds/a5j7AGgb/0/iguopin-web/iguopin-heilongjiang/packages/h5/src/styles/theme.less";